'use client';

// @mui
import { Container, Typography } from '@mui/material';
// components
import Stack from '@mui/material/Stack';
import Logo from '../../../components/logo';

// ----------------------------------------------------------------------

export default function FooterSimple() {
  return (
    <Container sx={{ py: 8 }}>
      <Stack alignItems='center'>
        <Logo sx={{ mb: 3, width: 250, maxWidth: '50%' }} />
        <Typography variant='body1'>
          Contact: sales@beermybrand.com
        </Typography>
        <Typography variant='body3' sx={{ color: 'text.secondary' }}>
          © Beer My Brand Oy. {new Date().getFullYear()}. All rights reserved.
        </Typography>
      </Stack>
    </Container>
  );
}
