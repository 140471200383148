'use client';

import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, isSimple = false, onDark = false, sx, ...other }, ref) => {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const logo =
    isSimple ?
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='100%'
        height='100%'
        fill='none'
        viewBox='0 0 512 512'
      >
        <ellipse cx='405.143' cy='338.571' fill='blue' rx='82.857' ry='82.857' />
        <path
          fill='blue'
          d='M114.742 355.332H256v66.097H24v-61.376l140.323-203.956H24V90h232v61.376L114.742 355.332z'
        />
      </svg>
      : <Box
        component='img'
        src={isLight ? '/assets/images/beer-my-brand-logo-orange.png' : '/assets/images/beer-my-brand-logo-white.png'}
        sx={{ width: 140, cursor: 'pointer', ...sx }}
      />;

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href='/' sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  isSimple: PropTypes.bool,
  onDark: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
